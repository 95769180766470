import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isEditor, getPrefixApi } from './utils';

export interface APPImagesResponse {
  primaryColor?: string;
  logoImageUrl?: string;
  coverImagesUrls?: string[];
  showLogoInHeader: boolean;
  headerTitle?: string;

}

export const getAppImages = async (flowApi: PlatformControllerFlowAPI) => {
  try {
    const prefix = getPrefixApi(flowApi);

    const api = isEditor(flowApi) ? '/api' : '';
    const { data } = await flowApi.httpClient.get<APPImagesResponse>(
      `${prefix}/wixapp${api}/v1/businesses/display-options`,
      {
        headers: {
          Authorization: flowApi.controllerConfig.appParams.instance,
        },
      },
    );
    return data;
  } catch (err) {
    return {
      primaryColor: '',
      logoImageUrl: '',
      coverImagesUrls: [],
      showLogoInHeader: true,
      headerTitle: '',

    };
  }
};
