import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DEFAULT_APP, SUPPORTED_APPS } from '../constants';

export interface APIPreferredAppResponse {
  wixPreferredApp: string;
  ownerPreferredApp: string;
  androidOwnerPreferredApp: string;
  iosOwnerPreferredApp: string;
}
export const getPreferredApp = async (flowApi: PlatformControllerFlowAPI) => {
  try {
    const { data } = await flowApi.httpClient.get<APIPreferredAppResponse>(
      `/wixapp/api/v1/preferred-apps/preference`,
      {
        headers: {
          Authorization: flowApi.controllerConfig.appParams.instance,
        },
      },
    );
    const {
      wixPreferredApp = '',
      ownerPreferredApp = '',
      androidOwnerPreferredApp = '',
      iosOwnerPreferredApp = '',
    } = data;
    const getValidApp = (preferredApp, fallbackApp, defaultApp) =>
      SUPPORTED_APPS.includes(preferredApp) ? preferredApp : fallbackApp;

    const sanitizeAppName = (appName) =>
      appName === 'Undefined' ? undefined : appName;

    const androidPreferredApp = sanitizeAppName(androidOwnerPreferredApp);
    const iosPreferredApp = sanitizeAppName(iosOwnerPreferredApp);
    const wixApp = sanitizeAppName(wixPreferredApp);
    const ownerApp = sanitizeAppName(ownerPreferredApp);

    const app = androidPreferredApp || iosPreferredApp || wixApp || ownerApp;
    const preferredApp = getValidApp(app, DEFAULT_APP, SUPPORTED_APPS);

    return {
      preferredApp,
      androidOwnerPreferredApp: androidPreferredApp,
      iosOwnerPreferredApp: iosPreferredApp,
      ownerPreferredApp: ownerApp,
    };
  } catch (err) {
    return {
      preferredApp: DEFAULT_APP,
      androidOwnerPreferredApp: '',
      iosOwnerPreferredApp: '',
      ownerPreferredApp: ''
    };
  }
};
