import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'SMS Messages',
  props: {
    // Here you can define your widget props
    myProp: {
      type: WidgetPropertyType.STRING,
    },
  },
  methods: {
    clearMessages: {
      params: [],
      description: 'Clears all the dispaly of the messges',
      returnsDescription: 'void function',
    },
    displayServerError: {
      params: [
        {
          name: 'message',
          description: 'message text',
        },
      ],
      description: 'Displays the text param as server error',
      returnsDescription: 'void function',
    },
    displayError: {
      params: [
        {
          name: 'message',
          description: 'message text',
        },
      ],
      description: 'Displays the text param as error',
      returnsDescription: 'void function',
    },
    displaySuccess: {
      params: [
        {
          name: 'message',
          description: 'message text',
        },
      ],
      description: 'Displays the text param as success',
      returnsDescription: 'void function',
    },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
