import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

/*
Detect Editor
*/
export const isEditor = (flowApi: PlatformControllerFlowAPI) =>
  !flowApi.environment.isViewer;

export const isMobileViewport = async (editorSDK: EditorSDK) => {
  const editorMode = await editorSDK.info.getCurrentViewport();
  return editorMode.type === 'MOBILE';
};
export const getPrefixApi = (flowApi: PlatformControllerFlowAPI) => {
  const api = isEditor(flowApi) ? '' : '/_api';
  return api;
};
export const getIsStudio = (context: EditorReadyOptions) => {
  return context.origin?.subType === 'STUDIO';
};
