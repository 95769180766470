import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { presets as mainPresets } from '../../utils/presets';
import { PresetIds } from '../../enums/presets';
import { removeElement } from './removeElement';
import appElements from '../../utils/appElements';
import { isMobileViewport } from '../../api/utils';
import { EditorSDK } from '@wix/platform-editor-sdk';

export const onPresetChange = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  event,
) => {
  const { preset, componentRef } = event.detail;
  const isMobile = await isMobileViewport(editorSDK);
  const selectedPreset =
    mainPresets.find(
      (p) => preset === p.id || preset === p.connectedMobilePresetID,
    ) || mainPresets[0];

  const [parentRefComponent] = await editorSDK.document.components.getAncestors(
    '',
    {
      componentRef,
    },
  )!;

  if (selectedPreset && parentRefComponent) {
    await editorSDK.components.refComponents.removeAllOverrides('', {
      componentRef: parentRefComponent,
    });
    if (isMobile) {
      await editorSDK.application.appStudioWidgets.changePreset('', {
        context: { viewport: 'MOBILE' },
        componentRef: parentRefComponent,
        stylePresetId: preset,
        layoutPresetId: preset,
      });
    } else {
      await editorSDK.application.appStudioWidgets.changePreset('', {
        context: { viewport: 'DESKTOP' },
        componentRef: parentRefComponent,
        stylePresetId: preset,
        layoutPresetId: preset,
      });
      if (!flowAPI.environment.isEditorX) {
        editorSDK.document.components.layout.update('', {
          componentRef: parentRefComponent,
          layout: {
            width: selectedPreset.width,
          },
        });
      }
    }
  }
  const widgetProps = isMobile
    ? { mobilePreset: selectedPreset.connectedMobilePresetID }
    : { preset: selectedPreset.id };
  await editorSDK.application.appStudioWidgets.props.set('token', {
    widgetRef: componentRef,
    newProps: widgetProps,
  });

  if (preset === PresetIds.centerTitleSubBadge) {
    await removeElement(editorSDK, event, appElements.QRCode.role);
  }
};
