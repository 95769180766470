import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    displayName: t('download_my_app.widget.device.label'),
    nickname: 'Device',
  });

  // widgetBuilder
  //   .gfpp()
  //   // .set({
  //   //   target: { role: 'deviceBorder' },
  //   // })
  //   .set('mainAction1', {
  //     target: { role: 'deviceBorder' },
  //   })
  //   .set('connect', { behavior: 'HIDE' })
  //   .set('design', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(
    'deviceBorder',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.global_design.device'),
      });

      connectedComponentsBuilder.gfpp().set('connect', { behavior: 'HIDE' });
      // .set('design', { behavior: 'HIDE' });

      connectedComponentsBuilder
        .gfpp('mobile')
        .set('design', { behavior: 'HIDE' });
    },
  );
};
