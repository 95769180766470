import { PreferredApp } from '../types/types';

const appsMap: { [key: string]: PreferredApp } = {
  WixFitnessApp: 'fit',
  WixRestaurantsApp: 'dine',
  WixBrandedApp: 'branded',
};

export default function preferredAppToKey(app: string): PreferredApp {
  return appsMap[app] || 'spaces';
}
