import type {
  AppManifest,
  GfppConfig,
  GfppDesktopConfig,
  GfppMobileConfig,
} from '@wix/platform-editor-sdk';
import { categories, mobilePresets, presets } from '../../utils/presets';

const controllerId = '778ca2ab-ffa5-4b98-b2e3-8727de469c86-mbv14';

export const setAppMobileGfpp = (appManifest: AppManifest) => {
  const appStageDataDefault =
    appManifest.controllersStageData![controllerId].default;
  const mobileAppGfpp = (appStageDataDefault.gfpp as GfppConfig)
    .mobile as GfppMobileConfig;
  // @ts-expect-error
  mobileAppGfpp.iconButtons.add = { behavior: 'HIDE' };
  // @ts-expect-error
  mobileAppGfpp.widgetDesign = appStageDataDefault.gfpp.desktop.widgetDesign;
};

export const setAppPresetsData = (
  appManifest: AppManifest,
  isMobile: boolean,
  isStudio: boolean,
) => {
  const appStageDataDefault =
    appManifest.controllersStageData![controllerId].default;
  const desktopAppGfpp = (appStageDataDefault.gfpp as GfppConfig)
    .desktop as GfppDesktopConfig;
  const mobileAppGfpp = (appStageDataDefault.gfpp as GfppConfig)
    .mobile as GfppMobileConfig;

  const myPresets = isStudio
    ? presets
        .filter((preset) => preset.isDisplayOnEditorX)
        .sort((a, b) => a.studioOrder - b.studioOrder)
    : presets;
  const presetsWeb = myPresets.map(({ name, ...rest }) => rest);
  const presetsMobile = mobilePresets.map(({ name, ...rest }) => rest);

  if (isStudio) {
    desktopAppGfpp.widgetPresets = {
      presets: isMobile ? presetsMobile : presetsWeb,
    };
  } else {
    desktopAppGfpp.widgetPresets = { presets: presetsWeb };
    // @ts-expect-error
    mobileAppGfpp.widgetPresets = { presets: presetsMobile };
  }
};
