import type { EditorSDK, WidgetInstallationType } from '@wix/platform-editor-sdk';
import { MobilePresetIds, PresetIds } from '../enums/presets';
import { getVariationId } from '../utils/presets';

const presets = {
  desktop: getVariationId(PresetIds.defaultBoxNew),
  mobile: MobilePresetIds.defaultBox,
};

export const installWidget = async (
  editorSDK: EditorSDK,
): Promise<void> => {
  await editorSDK.document.application.reloadManifest();
  await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: '99d9bbe6-575a-46dd-a94f-76d06228de9b',
    installationType: 'closed' as WidgetInstallationType,
    scopedPresets: {
      desktop: {
        layout: presets.desktop,
        style: presets.desktop,
      },
      mobile: {
        layout: presets.mobile,
        style: presets.mobile,
      },
    },
    layout: {
      height: 550,
      width: 980,
      x: 0,
      y: 100,
    },
    layouts: {
      componentLayout: {
        // id: '',
        minHeight: {
          type: 'px',
          value: 50,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'auto',
          //             type: 'px',
          //             value: 980,
        },
      },
      itemLayout: {
        id: '',
        alignSelf: 'start',
        margins: {
          left: {
            type: 'percentage',
            value: 15.999284485255783,
          },
          right: {
            type: 'percentage',
            value: 0,
          },
          top: {
            type: 'px',
            value: 81.99652862548828,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        justifySelf: 'start',
        type: 'GridItemLayout',
      },
      containerLayout: {
        // id: '',
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
    },
  });
};
