import type { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { editorAppEventListener } from './editorAppEventListener';
import { installWidget } from './installWidget';

export const onEditorReady = async (
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  if (options.firstInstall) {
    await installWidget(editorSDK);
  }

  editorAppEventListener(editorSDK, flowAPI, options);
};
