import { FlowAPI, FlowEditorSDK, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    displayName: t('download_my_app.widget.phone_inputs'),
    nickname: 'Phone inputWidget',
  });

  widgetBuilder.configureConnectedComponents(
    'smsWrapper',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.phone_inputs'),
      });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'dropdown1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.country_code.label'),
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('download_my_app.widget.country_code.main_action'),
          actionType: 'design',
          actionId: '',
        })
        .set('mainAction2', { behavior: 'HIDE' })
        // .set('design', { behavior: 'HIDE' })
        .set('settings', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'input1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.phone_number.label'),
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('download_my_app.widget.phone_number.main_action'),
          actionType: 'design',
          actionId: '',
        })
        .set('mainAction2', { behavior: 'HIDE' })
        // .set('design', { behavior: 'HIDE' })
        .set('settings', { behavior: 'HIDE' })
        .set('link', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' });
    },
  );

  widgetBuilder.configureConnectedComponents(
    'button1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.set({
        displayName: t('download_my_app.widget.send_button.label'),
      });

      connectedComponentsBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('download_my_app.widget.send_button.main_action'),
          actionId: 'openButtonwithoutLinkPanel',
        })
        .set('link', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' });
    },
  );
};
