import {
  defaulBoxNewImage,
  cropedDeviceImage,
  qr,
  qrBadges,
  qrSubtitleInBoxImage,
  overlappingImage,
  defaulBoxSolidBGImage,
  stripImage,
  titleQr,
  titleQrBadges,
  imageOnly2,
  badgesHorizontal,
  badgesVertical,
  centerTitleSubBadge,
} from '../assets/images/presets/Web';

import {
  badgeOnlyMobile,
  centerTitleSubBadgeMobile,
  cropedDeviceImageMobile,
  overlappingImageMobile,
  qrSubtitleInBoxImageMobile,
  titleQrMobile,
  defaulBoxMobile,
} from '../assets/images/presets/Mobile';
import appElements from './appElements';
import {
  MobilePresetIds,
  MobilePresetNames,
  PresetIds,
  PresetNames,
} from '../enums/presets';

export const categories: { name: string; id: string }[] = [
  // { name: 'All Presets', id: '1' },
  { name: 'With image', id: '2' },
  { name: 'Minimal', id: '3' },
];
export const mobilePresets: {
  name: string;
  id: string;
  src: any;
  categoryId: string;
}[] = [
  {
    name: MobilePresetNames.defaultBox,
    id: MobilePresetIds.defaultBox,
    src: defaulBoxMobile,
    categoryId: '2',
  },
  {
    name: MobilePresetNames.QRAndSubtitleInBox,
    id: MobilePresetIds.QRAndSubtitleInBox,
    src: qrSubtitleInBoxImageMobile,
    categoryId: '3',
  },
  {
    name: MobilePresetNames.croppedDevice,
    id: MobilePresetIds.croppedDevice,
    src: cropedDeviceImageMobile,
    categoryId: '2',
  },
  {
    name: MobilePresetNames.overlapping,
    id: MobilePresetIds.overlapping,
    src: overlappingImageMobile,
    categoryId: '2',
  },
  {
    name: MobilePresetNames.titleAndQRCode,
    id: MobilePresetIds.titleAndQRCode,
    src: titleQrMobile,
    categoryId: '3',
  },
  {
    name: MobilePresetNames.badgesOnly,
    id: MobilePresetIds.badgesOnly,
    src: badgeOnlyMobile,
    categoryId: '3',
  },
  {
    name: MobilePresetNames.centerTitleSubBadge,
    id: MobilePresetIds.centerTitleSubBadge,
    src: centerTitleSubBadgeMobile,
    categoryId: '3',
  },
];

export const presets: {
  name: string;
  id: string;
  src: any;
  layout: { width: string; height: string };
  withoutElements?: string[];
  width: number;
  height: number;
  isDisplayOnEditorX: boolean;
  studioOrder: number;
  connectedMobilePresetID: string;
  categoryId: string;
}[] = [
  {
    name: PresetNames.defaultBoxNew,
    id: PresetIds.defaultBoxNew,
    connectedMobilePresetID: MobilePresetIds.defaultBox,
    src: defaulBoxNewImage,
    layout: { width: '240px', height: '142px' },
    withoutElements: [
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 562,
    categoryId: '2',
    studioOrder: 1,
  },
  {
    name: PresetNames.croppedDevice,
    id: PresetIds.croppedDevice,
    connectedMobilePresetID: MobilePresetIds.croppedDevice,
    src: cropedDeviceImage,
    layout: { width: '240px', height: '114px' },
    withoutElements: [
      appElements.QRCode.role,
      appElements.badges.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: false,
    width: 980,
    height: 420,
    categoryId: '2',
    studioOrder: 0,
  },
  {
    name: PresetNames.centerTitleSubBadge,
    id: PresetIds.centerTitleSubBadge,
    connectedMobilePresetID: MobilePresetIds.centerTitleSubBadge,
    src: centerTitleSubBadge,
    layout: { width: '240px', height: '54px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.smsInputs.role,
      appElements.QRCodeWithSubTitle.role,
    ],
    isDisplayOnEditorX: true,
    width: 450,
    height: 181,
    categoryId: '3',
    studioOrder: 3,
  },
  {
    name: PresetNames.badgesHorizontal,
    id: PresetIds.badgesHorizontal,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: badgesHorizontal,
    layout: { width: '110px', height: '55px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 290,
    height: 43,
    categoryId: '3',
    studioOrder: 4,
  },
  {
    name: PresetNames.overlapping,
    id: PresetIds.overlapping,
    connectedMobilePresetID: MobilePresetIds.overlapping,
    src: overlappingImage,
    layout: { width: '240px', height: '153px' },
    withoutElements: [
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 603,
    categoryId: '2',
    studioOrder: 5,
  },
  {
    name: PresetNames.imgOnly,
    id: PresetIds.imgOnly,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: imageOnly2,
    layout: { width: '110px', height: '148px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.badges.role,
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
    ],
    isDisplayOnEditorX: true,
    width: 316,
    height: 493,
    categoryId: '2',
    studioOrder: 9,
  },
  {
    name: PresetNames.strip,
    id: PresetIds.strip,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: stripImage,
    layout: { width: '240px', height: '57px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 226,
    categoryId: '3',
    studioOrder: 6,
  },
  {
    name: PresetNames.QRAndBadges,
    id: PresetIds.QRAndBadges,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: qrBadges,
    layout: { width: '110px', height: '52px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 255,
    height: 94,
    categoryId: '3',
    studioOrder: 7,
  },
  {
    name: PresetNames.titleAndQRCode,
    id: PresetIds.titleAndQRCode,
    connectedMobilePresetID: MobilePresetIds.titleAndQRCode,
    src: titleQr,
    layout: { width: '109px', height: '148px' },
    withoutElements: [
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.badges.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 136,
    height: 194,
    categoryId: '3',
    studioOrder: 8,
  },
  {
    name: PresetNames.defaultBoxAndSolidBG,
    id: PresetIds.defaultBoxAndSolidBG,
    connectedMobilePresetID: MobilePresetIds.overlapping,
    src: defaulBoxSolidBGImage,
    layout: { width: '240px', height: '120px' },
    withoutElements: [
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: false,
    width: 980,
    height: 488,
    categoryId: '2',
    studioOrder: 0,
  },

  {
    name: PresetNames.QRAndSubtitleInBox,
    id: PresetIds.QRAndSubtitleInBox,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: qrSubtitleInBoxImage,
    layout: { width: '240px', height: '115px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.QRCode.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 980,
    height: 436,
    categoryId: '3',
    studioOrder: 2,
  },

  {
    name: PresetNames.tiltleAndQRcodeAndBadges,
    id: PresetIds.tiltleAndQRcodeAndBadges,
    connectedMobilePresetID: MobilePresetIds.QRAndSubtitleInBox,
    src: titleQrBadges,
    layout: { width: '240px', height: '77px' },
    withoutElements: [
      appElements.mobilePreview.role,
      appElements.smsInputs.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 576,
    height: 183,
    categoryId: '3',
    studioOrder: 10,
  },

  {
    name: PresetNames.badgesVertical,
    id: PresetIds.badgesVertical,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: badgesVertical,
    layout: { width: '110px', height: '78px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.QRCode.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 144,
    height: 94,
    categoryId: '3',
    studioOrder: 11,
  },

  {
    name: PresetNames.QRcode,
    id: PresetIds.QRcode,
    connectedMobilePresetID: MobilePresetIds.badgesOnly,
    src: qr,
    layout: { width: '110px', height: '77px' },
    withoutElements: [
      appElements.title.role,
      appElements.description.role,
      appElements.smsInputs.role,
      appElements.mobilePreview.role,
      appElements.badges.role,
      appElements.QRCodeWithSubTitle.role,
      appElements.QRInDevice.role,
    ],
    isDisplayOnEditorX: true,
    width: 92,
    height: 92,
    categoryId: '3',
    studioOrder: 12,
  },
];

export const getVariationId = (preset: string) => {
  // remove externalPreset-
  return preset; // .slice(preset.indexOf('-') + 1);
};
