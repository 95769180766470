import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { onWidgetDeletion } from '../utils/Editor/onLastWidgetDeletion';
import {
  onPresetChange,
  onSitePublish,
  onClickWidgetGFPP,
  onClickComponentGFPP,
} from './utils';
import { initDeviceSwitchEventListener } from './onDeviceChanged';
import { EditorSDKUtils } from '../utils/Editor/editorSdkUtils';

export const editorAppEventListener = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
) => {

  const editorSDKUtils = new EditorSDKUtils(editorSDK);

  await editorSDK.addEventListener('siteWasPublished', async (event) => {
    await onSitePublish(editorSDK, flowAPI);
  });

  await initDeviceSwitchEventListener({ editorSDK });

  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    await onClickWidgetGFPP(editorSDK, flowAPI, options, event);
  });

  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    await onClickComponentGFPP(editorSDK, flowAPI, options, event);
  });

  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      await onPresetChange(editorSDK, flowAPI, event);
    },
  );

  await editorSDKUtils.onDeleteWidgetListener(async () => {
    return onWidgetDeletion(editorSDKUtils);
  });
};
