import appElements from '../../utils/appElements';
import { presets } from '../../utils/presets';

const COMPS_DATA = {
  mobilePreview: {
    role: appElements.mobilePreview.role,
    biName: 'app_preview',
    labelKey: 'download_my_app.widget.elements_gfpp.mobile_preview',
  },
  title: {
    role: appElements.title.role,
    biName: 'app_title',
    labelKey: 'download_my_app.widget.elements_gfpp.title',
  },
  description: {
    role: appElements.description.role,
    biName: 'description_text',
    labelKey: 'download_my_app.widget.elements_gfpp.description',
  },
  smsInputs: {
    role: appElements.smsInputs.role,
    biName: 'sms_invite',
    labelKey: 'download_my_app.widget.elements_gfpp.sms',
  },
  badges: {
    role: appElements.badges.role,
    biName: 'app_store_icons',
    labelKey: 'download_my_app.widget.elements_gfpp.badges',
  },
  QRCode: {
    role: appElements.QRCode.role,
    biName: 'scan_qr',
    labelKey: 'download_my_app.widget.elements_gfpp.qr',
  },
  QRCodeWithSubTitle: {
    role: appElements.QRCodeWithSubTitle.role,
    labelKey: 'download_my_app.widget.elements_gfpp.qr',
  },
};

const CATEGORIES_IDS = {
  elements: 'elements',
  actionElements: 'actionElements',
};

export const categoriesData = [
  {
    id: CATEGORIES_IDS.elements,
    title: 'download_my_app.widget.elements_gfpp.first_category',
  },
  {
    id: CATEGORIES_IDS.actionElements,
    title: 'download_my_app.widget.elements_gfpp.second_category',
  },
];

export const elementsData = [
  {
    label: COMPS_DATA.mobilePreview.labelKey,
    identifier: { role: COMPS_DATA.mobilePreview.role },
    categoryId: CATEGORIES_IDS.elements,
    biName: COMPS_DATA.mobilePreview.biName,
    index: 0,
  },
  {
    label: COMPS_DATA.title.labelKey,
    identifier: { role: COMPS_DATA.title.role },
    categoryId: CATEGORIES_IDS.elements,
    biName: COMPS_DATA.title.biName,

    index: 1,
  },
  {
    label: COMPS_DATA.description.labelKey,
    identifier: { role: COMPS_DATA.description.role },
    categoryId: CATEGORIES_IDS.elements,
    biName: COMPS_DATA.description.biName,
    index: 2,
  },
  {
    label: COMPS_DATA.smsInputs.labelKey,
    identifier: { role: COMPS_DATA.smsInputs.role },
    categoryId: CATEGORIES_IDS.actionElements,
    biName: COMPS_DATA.smsInputs.biName,
    index: 3,
  },
  {
    label: COMPS_DATA.badges.labelKey,
    identifier: { role: COMPS_DATA.badges.role },
    categoryId: CATEGORIES_IDS.actionElements,
    biName: COMPS_DATA.badges.biName,
    index: 4,
  },
  {
    label: COMPS_DATA.QRCode.labelKey,
    identifier: { role: COMPS_DATA.QRCode.role },
    categoryId: CATEGORIES_IDS.actionElements,
    biName: COMPS_DATA.QRCode.biName,
    index: 5,
  },
  {
    label: COMPS_DATA.QRCodeWithSubTitle.labelKey,
    identifier: { role: COMPS_DATA.QRCodeWithSubTitle.role },
    categoryId: CATEGORIES_IDS.actionElements,
    index: 6,
  },
];

export function getElementsByPreset(selectedPreset: string, t) {
  const elems = elementsData
    .filter((elementData) => {
      const preset = presets.find((preset) =>
        preset.id.includes(selectedPreset),
      ) || {
        withoutElements: null,
      };
      const res = (preset.withoutElements || []).find(
        (noElem) => noElem === elementData.identifier.role,
      );
      return !res;
    })
    .map((elem, index) => ({ ...elem, label: t(elem.label), index }));
  return elems;
}

export function getCategories(t, elementsData) {
  return categoriesData
    .map((category) => ({
      ...category,
      title: t(category.title),
    }))
    .filter((category) =>
      elementsData.some((element) => element.categoryId == category.id),
    );
}
