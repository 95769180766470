

export const onWidgetDeletion = async (editorSDKUtils) => {
  const isLast = await isLastWidget(editorSDKUtils);
  if (isLast) {
    await editorSDKUtils.uninstallApplication();
  }
};

const isLastWidget = async (editorSDKUtils): Promise<boolean> => {
  const allRefs = await editorSDKUtils.getAllAppRefComponents();
  const noWidgetsLeft = !allRefs?.length;

  return noWidgetsLeft;
};
