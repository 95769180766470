export enum PresetIds {
  defaultBoxNew = 'variants-le5pgraf',
  croppedDevice = 'variants-l8x08zde',
  QRAndSubtitleInBox = 'variants-l74uh9f2',
  overlapping = 'variants-l5p9yyxn',
  defaultBoxAndSolidBG = 'variants-l5tgs9a2',
  strip = 'variants-l4pgf8jl',
  tiltleAndQRcodeAndBadges = 'variants-l4v1raum',
  badgesHorizontal = 'variants-lbnkpqc9',
  QRAndBadges = 'variants-lbc812vw',
  QRcode = 'variants-lbc91cpu',
  badgesVertical = 'variants-lbc90vx7',
  imgOnly = 'variants-lda25eo9',
  titleAndQRCode = 'variants-lypmbjwe',
  centerTitleSubBadge = 'variants-lsvttf1b',
}

export enum PresetNames {
  defaultBoxNew = 'Default Box New',
  croppedDevice = 'Cropped Device',
  QRAndSubtitleInBox = 'QR+sub title in box',
  overlapping = 'Overlapping',
  defaultBoxAndSolidBG = 'Default Box + Solid BG',
  strip = 'Strip',
  tiltleAndQRcodeAndBadges = 'Tiltle + QR code + Badges',
  badgesHorizontal = 'Badges horizontal',
  QRAndBadges = 'QR + badges',
  QRcode = 'QR code',
  badgesVertical = 'Badges vertical',
  imgOnly = 'Img Only',
  titleAndQRCode = 'Test Tiltle + QR code',
  centerTitleSubBadge = 'Center title+sub+badge+QR',
}

export enum MobilePresetNames {
  QRAndSubtitleInBox = 'Mobile QR+sub title in box',
  defaultBox = 'Mobile Default',
  croppedDevice = 'Mobile Cropped Device',
  titleAndQRCode = 'Mobile Tile + QR Code',
  badgesOnly = 'Mobile badge only',
  overlapping = 'Mobile Overlapping',
  centerTitleSubBadge = 'Mobile Center title+sub+badge',
}

export enum MobilePresetIds {
  QRAndSubtitleInBox = 'variants-l7ylym0r',
  defaultBox = 'variants-l737ukhy',
  croppedDevice = 'variants-lj2nn1wq',
  titleAndQRCode = 'variants-lj43wse6',
  badgesOnly = 'variants-lj451hr9',
  overlapping = 'variants-lj2p6hd0',
  centerTitleSubBadge = 'variants-lsxbnay1',
}
