import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { PresetIds } from '../../enums/presets';

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Default Box Preset',
  props: {
    // Here you can define your widget props
    title: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'default_prop',
    },
    description: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'default_prop',
    },
    mobilePreset: {
      type: WidgetPropertyType.STRING,
      defaultValue: undefined,
    },
    preset: {
      type: WidgetPropertyType.STRING,
      defaultValue: PresetIds.defaultBoxNew,
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
