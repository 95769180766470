const appElements = {
  mobilePreview: {
    role: 'device1',
  },
  title: {
    role: 'title',
  },
  description: {
    role: 'description',
  },
  smsInputs: {
    role: 'phoneInput2',
    // role: 'smsWrapper',
  },
  badges: {
    role: 'badges2',
  },
  QRCode: {
    role: 'qrCode3',
  },
  QRCodeWithSubTitle: {
    role: 'qrAndTitleWrapper',
  },
  QRInDevice: {
    role: 'qrCode1',
  },
};

export default appElements;
