import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getPrefixApi } from './utils';

export interface APICodeResponse {
  code: string;
  url: string;
}
export const getCode = async (flowApi: PlatformControllerFlowAPI) => {
  const prefix = getPrefixApi(flowApi);
  const headers = flowApi.controllerConfig && {
    headers: {
      Authorization: flowApi.controllerConfig.appParams.instance,
    },
  };
  const { data } = await flowApi.httpClient.post<APICodeResponse>(
    `${prefix}/clubs/api/v1/invites`,
     headers || {},
    {},
  );
  return data;
};
