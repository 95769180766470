import { HttpClient } from '@wix/http-client';

const httpClient = new HttpClient();

export function externalGet(url: string): Promise<any> {
  return httpClient.get(url).then((res) => res.data);
}

export function externalPost<T>(
  url: string,
  headers: any,
  data: any,
): Promise<T> {
  return httpClient
    .post<T>(url, data, {
      headers,
    })
    .then((res) => res.data);
}
