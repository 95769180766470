import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getPrefixApi } from './utils';
import { getCode } from './getCode';
import { DEFAULT_APP, SUPPORTED_APPS } from '../constants';
import { getPreferredApp } from './getPreferredApp';

export interface APISiteNameResponse {
  title: string;
  url: string;
  metaSiteId: string;
  preferredApp: string;
}

export const getSiteName = async (flowApi: PlatformControllerFlowAPI) => {
  try {
    const codeData = await getCode(flowApi);
    const prefix = getPrefixApi(flowApi);
    const headers = flowApi.controllerConfig
      ? {
          headers: {
            Authorization: flowApi.controllerConfig.appParams.instance,
          },
        }
      : {};
    const { data } = await flowApi.httpClient.get<APISiteNameResponse>(
      `${prefix}/clubs/api/v1/invites/details`,
      {
        headers,
        params: {
          code: codeData.code,
        },
      },
    );
    const {
      preferredApp,
      androidOwnerPreferredApp,
      iosOwnerPreferredApp,
      ownerPreferredApp,
    } = await getPreferredApp(flowApi);

    return {
      ...data,
      androidOwnerPreferredApp,
      iosOwnerPreferredApp,
      ownerPreferredApp,
      preferredApp,
    };
  } catch (err) {
    return {
      title: '',
      url: '',
      metaSiteId: '',
      preferredApp: DEFAULT_APP,
      androidOwnerPreferredApp: '',
      iosOwnerPreferredApp: '',
      ownerPreferredApp: '',
    };
  }
};

export default getSiteName;
