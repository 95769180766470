import { WidgetDesignManifestBuilder } from '@wix/app-manifest-builder/dist/types/controller/widgetDesign/widgetDesignManifestBuilder';
import { WriteOnlyBuilder } from '@wix/app-manifest-builder/dist/types/types';
import appElements from './appElements';

const tabs = [
  {
    labelKey: 'download_my_app.widget.global_design.background',
    ids: ['appState'],
  },
  {
    labelKey: 'download_my_app.widget.global_design.inputs',
    ids: ['input1', 'dropdown1'],
  },
  {
    labelKey: 'download_my_app.widget.global_design.button',
    ids: ['button1'],
  },
  {
    labelKey: 'download_my_app.widget.global_design.app_title',
    ids: [appElements.title.role],
  },
  {
    labelKey: 'download_my_app.widget.global_design.description',
    ids: [appElements.description.role],
  },
  {
    labelKey: 'download_my_app.widget.global_design.device',
    ids: ['deviceBorder'],
  },
  // {
  //   labelKey: 'download_my_app.widget.global_design.device_background',
  //   ids: ['devicebg'],
  // },
];

export default function globalDesignBuilder(
  widgetDesignBuilder: WriteOnlyBuilder<WidgetDesignManifestBuilder>,
  t,
) {
  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

  tabs.forEach(({ labelKey, ids }) =>
    widgetDesignTabsBuilder.addTab((tabBuilder) =>
      tabBuilder
        .set({
          label: t(labelKey),
        })
        .groups()
        .set({
          roles: ids,
        }),
    ),
  );
}
